.profile-table-key {
  /* margin: 0;
  font-weight: 500;
  font-size: 15px;
  flex-basis: 160px;
  padding-right: 0; */
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  flex-basis: 145px;
  padding-right: 10px;
}

.profile-table-value {
  /* flex-grow: 1; */
  flex-grow: 1;
  transition: height 0.1s linear;
}

.profile-table-row {
  display: flex;
  flex-direction: row;
  padding: 20px 32px;
}

.payment-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-brand {
  display: inline-block;
  width: 24px;
  height: 15px;
  margin-right: 10px;
  background: url("../../../assets/icons/card/unknown.svg") no-repeat left
    center;
}

.card-brand.amex {
  background-image: url("../../../assets/icons/card/amex.svg");
}

.card-brand.diners {
  background-image: url("../../../assets/icons/card/diners.svg");
}

.card-brand.discover {
  background-image: url("../../../assets/icons/card/discover.svg");
}

.card-brand.jcb {
  background-image: url("../../../assets/icons/card/jcb.svg");
}

.card-brand.mastercard {
  background-image: url("../../../assets/icons/card/mastercard.svg");
}

.card-brand.visa {
  background-image: url("../../../assets/icons/card/visa.svg");
}

span.full {
  color: #e19e50;
  font-weight: 500;
}

.update {
  background: #fff;
  border: 2px solid #c7d1d9;
  border-radius: 4px;
  outline: unset;
  min-width: 100px;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: min-content; */
  margin: 0 0 0 10px;
  cursor: pointer;
  padding: 3px;
}
.update:hover {
  color: rgba(0, 0, 0, 0.65);
}
ul {
  color: #8898aa;
  font-size: 15px;
  line-height: 32px;
  margin: 0;
  padding-top: 12px;
  padding-left: 0;
  list-style-type: none;
}

p {
  margin: 0;
}

.stripe-label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  width: 100%;
}

.stripe-form {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e6ebf1;
}
/*
.StripeElement {
  display: block;
  max-width: 500px;
  font-size: 15px;
  font-family: "Source Code Pro", monospace;
  box-shadow: none;
  border: 0;
  outline: 0;
  background: white;
}

.StripeElement--focus {
  box-shadow: none;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

button.stripe-submit {
  color: white;
  background: #08979c;
  width: 100%;
  font-weight: 600;
  padding: 0 20px;
  height: 48px;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  margin-top: 20px;
}

button.stripe-submit:active,
button.stripe-submit.loading {
  background: #08979c;
} */

.pricing-table .interval {
  font-size: 19px;
  line-height: 1.8;
  font-weight: 400;
  color: #8f8f8f;
  margin-left: 4px;
}

.pricing-table .plan-name {
  font-size: 22px;
  line-height: 1.5;
}

.pricing-table .amount {
  font-weight: 300;
  font-size: 42px;
  line-height: 1.5;
}
.pricing-table li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.pricing-table .feature {
  color: #8f8f8f;
  font-size: 15px;
  line-height: 1.5;
}

ul.ant-card-actions li {
  justify-content: center;
}

.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}
/* label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
} */
button.stripe-submit {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
  width: 100%;
}
form {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e6ebf1;
}
button.stripe-submit:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.subscribe input.ant-input,
.StripeElement,
form .ant-select.half-width {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}
input.ant-input::placeholder,
form .ant-select.half-width::placeholder {
  color: #aab7c4;
}
input.ant-input:focus,
.StripeElement--focus,
form .ant-select.half-width:focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.stripe-label input.ant-input,
form .ant-select.half-width {
  font-size: 14px;
  color: #424770;
  letter-spacing: 0.025em;
  color: #aab7c4;
  padding: 10px 14px;
}

.stripe-label input.full-width {
  width: 100%;
  margin: 10px 0 20px 0;
  color: black;
}

.stripe-label input.half-width {
  width: 96%;
  margin: 10px 0 20px 0;
  color: black;
}

form .ant-select.half-width {
  width: 96%;
  padding: 0;
}
form .ant-select.half-width .ant-select-selection {
  border: none;
}
.stripe-label .ant-col.ant-form-item-label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  padding: 0;
}

form .ant-row.ant-form-item.stripe-label {
  margin: 0;
}

.ant-col.ant-form-item-label.summary {
  border-bottom: 3px solid #e6ebf1;
  margin-bottom: 3px;
}

.subscribe .ant-select-selection-selected-value {
  color: black;
}
